import { db, fb, auth, storage } from '../../config/firebase';
import { clearUser, loginFailed, loginSuccess, logoutFxn, signupFailed, storeUserData,saveSchool } from '../reducers/auth.slice';
import { v4 as uuidv4 } from 'uuid';
import { notifyErrorFxn, notifySuccessFxn } from 'src/utils/toast-fxn';
import { clearGroup } from '../reducers/group.slice';
import { saveThemeColor, saveThemeImage } from '../reducers/settings.slice';


  export const signin = (user, navigate, setLoading) => async (dispatch) => {
    fb.auth().signInWithEmailAndPassword(user.email, user.password)
    .then((userCredential) => {
      // Signed in
      var user = userCredential.user;
      console.log('Signed In user is: ', user.email);
       dispatch(fetchUserData(user.uid, "sigin", navigate, setLoading));
    })
    .catch((error) => {
      setLoading(false);
      var errorCode = error.code;
      var errorMessage = error.message;
      notifyErrorFxn(errorMessage);
      console.log('Error Code is: ', errorCode, + ' Msg is: ', errorMessage);
      dispatch(loginFailed(errorMessage));
    });

};


export const signup = (user, navigate, setLoading) => async (dispatch) => {
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  var today = new Date();

  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };

  const randomRegistrationId = generateRandomString(10); 

  fb.auth().createUserWithEmailAndPassword(
    user.email,
    user.password
  ).then((res) => {
    console.log("Good to go...");
    return db.collection('teachers').doc(res.user.uid).set({
      teacherId: res.user.uid,
      registrationId: randomRegistrationId, 
      email: user.email,
      sname: user.sname,
      fname: user.fname,
      lname: user.lname,
      phone: user.phone,
      password: user.password,
      accountCreated: today.toLocaleDateString("en-US", options),
    })
  }).then(() => {
    notifySuccessFxn('Registered Successfully✔');
    navigate('/login', { replace: true });
  }).catch((err) => {
    console.error("Error signing up: ", err);
    var errorMessage = err.message;
    notifyErrorFxn(errorMessage);
    dispatch(signupFailed({ errorMessage }));
    setLoading(false);
  });
};


export const uploadImage = (user, file, navigate, setLoading) => async (dispatch) => {
  const imageName = uuidv4() + '.' + file?.name?.split('.')?.pop();
  console.log('File Name: ', imageName);
  const uploadTask = storage.ref(`profile_images/${imageName}`).put(file);
  uploadTask.on(
    "state_changed",
    snapshot => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      // setProgress(progress);
    },
    error => {
      console.log(error);
    },
    () => {
      storage
        .ref("profile_images")
        .child(imageName)
        .getDownloadURL()
        .then(url => {
          console.log('Image URL: ', url);
          dispatch(signup(user, file, navigate, setLoading, url));
        });
    }
  );
}


export const fetchUserData = (id, type, navigate, setLoading) => async (dispatch) => {
  var user = db.collection("teachers").doc(id);
  user.get().then((doc) => {
  if (doc.exists) {
    // console.log("User Data:", doc.data());

    db.collection('schools').doc(doc.data().schoolId).get()
 .then((doc2)=>{ 

 
  dispatch(saveSchool(doc2.data()))
  dispatch(saveThemeColor(doc2.data().settings.themeColor))

  dispatch(saveThemeImage(doc2.data().settings.themeImage))
/**=============================================== */


    dispatch(storeUserData(doc.data()));
    if(type === "sigin"){
      notifySuccessFxn("Logged In😊");
      navigate('/dashboard/home', { replace: true });
    }


  }).catch((error) => {
    console.log("Error getting document:", error);
    notifyErrorFxn("School that the teacher belongs to not found");
  
  });

  } else {
      setLoading(false);
      notifyErrorFxn("Unauthorized❌")
      console.log("No such document!");
  }
}).catch((error) => {
  console.log("Error getting document:", error);
});
return user;
};


export const uploadProfileImage = (profileData, file, userID, navigate, setLoading) => async (dispatch) => {
  const imageName = uuidv4() + '.' + file?.name?.split('.')?.pop();
  console.log('File Name: ', imageName);
  const uploadTask = storage.ref(`profile_images/${imageName}`).put(file);
  uploadTask.on(
    "state_changed",
    snapshot => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      // setProgress(progress);
    },
    error => {
      console.log(error);
    },
    () => {
      storage
        .ref("profile_images")
        .child(imageName)
        .getDownloadURL()
        .then(url => {
          console.log('Image URL: ', url);
          dispatch(updateProfile(profileData, userID, file, navigate, setLoading, url));
        });
    }
  );
}


export const updateProfile = (profileData, userID, file, navigate, setLoading, url) => async (dispatch) => {
  // return  
  db.collection('employees').doc(userID).update({
    paymentLink: profileData.paymentLink,
    imageUrl: url,
  }).then((res)=>{
       if(profileData?.password){
        //update password start
        const user = auth.currentUser;
        user.updatePassword(profileData.password)
          .then(() => {
            setLoading(false);
            console.log("Password updated successfully");
            notifySuccessFxn("Updated successfully");
            navigate('/dashboard/home', { replace: true });
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error updating password: ", error);
            notifyErrorFxn(error.message);
          });
       //update password end
       }else{
        setLoading(false);
        console.error("No Password to update");
        notifySuccessFxn("Updated successfully");
        navigate('/dashboard/home', { replace: true });
       }
     
  }).catch((err) => {
    setLoading(false);
    console.log("ERR-: ", err);
  })
}






export const uploadProfileSettings = (userPreferences, file, userID) => async (dispatch) => {
  const imageName = uuidv4() + '.' + file?.name?.split('.')?.pop();
  console.log('File Name-->: ', imageName);
  const uploadTask = storage.ref(`profile_images/${imageName}`).put(file);
  uploadTask.on(
    "state_changed",
    snapshot => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      // setProgress(progress);
    },
    error => {
      console.log(error);
    },
    () => {
      storage
        .ref("profile_images")
        .child(imageName)
        .getDownloadURL()
        .then(url => {
          console.log('Image URL: ', url);
          dispatch(updateUserSettings(userPreferences, userID, url));
        });
    }
  );
}




export const updateUserSettings = (userPreferences, userID,url) => async (dispatch) => {
  // return  

  db.collection('teachers').doc(userID).get().then((dac)=>{

  if(url){
    userPreferences = {...userPreferences,profileImage:url}
  }

  console.log("STORED URL-->",url)


if(!url )
{

  db.collection('teachers').doc(userID).update({
   
              profileImage: dac.data().profileImage? dac.data().profileImage:""
           
    }
    
   ).then((res)=>{
        if(userPreferences?.password){
         //update password start
         const user = auth.currentUser;
         user.updatePassword(userPreferences.password)
           .then(() => {
             //setLoading(false);
             console.log("our first update to the database went swimmingly");
             notifySuccessFxn("Updated successfully");
            // navigate('/dashboard/home', { replace: true });
           })
           .catch((error) => {
             //setLoading(false);
             console.error("Error updating ,first update to db: ", error);
             notifyErrorFxn(error.message);
             return
           });
        //update password end
        }else{
         //setLoading(false);
          //setLoading(false);
          if(!userPreferences.password){
            console.error("No Password to update");
            notifySuccessFxn("Updated successfully");
            dispatch(fetchUserData(userID, "update"));
            }
         //navigate('/dashboard/home', { replace: true });
        }
      
   }).catch((err) => {
     //setLoading(false);
     console.log("ERR-: ", err);
   })


}


  else{
  db.collection('teachers').doc(userID).update({
   
      profileImage: url,
    
   
  }).then((res)=>{
       if(userPreferences?.password){
        //update password start
        const user = auth.currentUser;
        user.updatePassword(userPreferences.password)
          .then(() => {
            //setLoading(false);
            console.log("our first update to the database went swimmingly");
            notifySuccessFxn("Updated successfully");
          dispatch(fetchUserData(userID, "update"));
           // navigate('/dashboard/home', { replace: true });
          })
          .catch((error) => {
            //setLoading(false);
            console.error("Error updating ,first update to db: ", error);
            notifyErrorFxn(error.message);
            return
          });
       //update password end
       }else{
        //setLoading(false);
        if(!userPreferences.password){
        console.error("No Password to update");
        notifySuccessFxn("Updated successfully");
        }
        //navigate('/dashboard/home', { replace: true });
       }
     
  }).catch((err) => {
   
    console.log("ERR-: ", err);
  })

}


  }) //END OF INITIAL GET OF teachers DATA


  db.collection('teachers').doc(userID).get().then((doc)=>{

    //dispatch(saveThemeImage(doc.data().settings.profileImage))
    //dispatch(saveThemeColor(doc.data().settings.themeColor))
    dispatch(storeUserData(doc.data()));

  }).then(()=>{
    //notifySuccessFxn("Updated successfully");
    console.log("final process of teacher update is complete, it may or may not have been successful")
  })











  
}


export const logout = (navigate) => async (dispatch) => {
  fb.auth().signOut().then(() => {
    dispatch(logoutFxn());
    dispatch(clearUser());
    dispatch(clearGroup());
  navigate('/login'/*, { replace: true }*/);
  notifySuccessFxn("Logged out!")
    console.log('logout successful!');
  }).catch((error) => {
    // An error happened.
    console.log('logout failed response: ', error.message);
  });
  
}