import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, FormControl, Box, Select, MenuItem, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyGroups } from 'src/redux/actions/group.action';
import { fetchUserData, updateUserSettings, uploadProfileSettings } from 'src/redux/actions/auth.action';

import merge from 'lodash/merge';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { fetchMyTransactions } from 'src/redux/actions/transaction.action';
import CustomToggleSwitch from 'src/components/buttons/CustomToogleSwitch';
import CustomSearchBar from 'src/components/global/CustomSearchBar';
import SearchIcon from '@mui/icons-material/Search';
import ViewStudents from 'src/components/students/ViewStudents';
import AddStudent from 'src/components/students/AddStudent';
import { getStudents } from 'src/redux/actions/student.action';
import SettingsTopBox from 'src/components/students/SettingsTopBox';
import SettingsBottomBox from 'src/components/students/SettingsBottomBox';
import { saveThemeColor, saveThemeImage } from 'src/redux/reducers/settings.slice';



export default function SettingsPage() {
  const theme = useTheme();
    
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const { themeColor,themeColorUnsaved,themeImageUnsaved,themeImageBlob,newPassword } = useSelector((state) => state.settings);


  useEffect(()=>{

    if(!themeColor){
    dispatch(saveThemeColor( user && user.settings &&  user.settings.themeColor))
    dispatch(saveThemeImage(user && user.settings &&  user.settings.themeImage))
    }
   
   
     },[])


  const { students } = useSelector((state) => state.student);

  const preferredSettings = {
    themeColorUnsaved:"",
    themeImageUnsaved:"",
    password:newPassword,
    profileImage:user && user.profileImage?user.profileImage:""
  }

  useEffect(() => {
  //  / dispatch(fetchMyGroups(user?.coolers));
  //  / dispatch(fetchMyTransactions(user?.id));
    console.log("Transac Changed.");
  }, [user])

  useEffect(() => {
    dispatch(getStudents());
    dispatch(fetchUserData(user?.id));
  }, [])



  const [selectedOption, setSelectedOption] = useState('');
  const [activeButton, setActiveButton] = useState('viewStudents');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleViewStudentsClick = () => {
    setActiveButton('viewStudents');
 
  };

  const handleAddStudentsClick = () => {
    setActiveButton('addStudents');
  };

  return (
    <>

      <Container maxWidth="xl">
       {/* <Grid container spacing={2} alignItems="center">
     <CustomToggleSwitch activeButton={activeButton} setActiveButton={setActiveButton} handleViewStudentsClick={handleViewStudentsClick} handleAddStudentsClick={handleAddStudentsClick}/>
     <Grid item sx={{mb: 2}}>
     <FormControl sx={{ minWidth: 140 }}>
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            displayEmpty
            label=""
            sx={{
            
              minWidth: 140,
              p: 1,
            }}
          >
            <MenuItem value="">
              Sélectionner Classe
            </MenuItem>
             <MenuItem value={'Level 1'}>Level 1</MenuItem>
        <MenuItem value={'Level 2'}>Level 2</MenuItem>
        <MenuItem value={'Level 3'}>Level 3</MenuItem>
        <MenuItem value={'Level 4'}>Level 4</MenuItem>
        <MenuItem value={'Level 5'}>Level 5</MenuItem>
        <MenuItem value={'Level 7'}>Level 7</MenuItem>
        <MenuItem value={'Level 8'}>Level 8</MenuItem>
        <MenuItem value={'Level 9'}>Level 9</MenuItem>
        <MenuItem value={'Level 10'}>Level 10</MenuItem>
        <MenuItem value={'Level 11'}>Level 11</MenuItem>
        <MenuItem value={'Level 12'}>Level 12</MenuItem>
        <MenuItem value={'Level 13'}>Level 13</MenuItem>
          </Select>
        </FormControl>
      </Grid>
     <Grid item sx={{mb: 2}}>
     <FormControl sx={{ minWidth: 140 }}>
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            displayEmpty
            label=""
            sx={{
           
              minWidth: 140,
              p: 1,
            }}
          >
            <MenuItem value="">
              Select Section
            </MenuItem>
            <MenuItem value={1}>Option 1</MenuItem>
            <MenuItem value={2}>Option 2</MenuItem>
            <MenuItem value={3}>Option 3</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      &nbsp; &nbsp;
      <Box sx={{ width: '20%' }}>
        <CustomSearchBar  title={"Rechercher étudiant"} />
      </Box>
      <Box sx={{ flexGrow: 1}}>
        <Button
          variant="contained"
          style={{ minHeight: '50px', minWidth: '45px', backgroundColor: '#000000' }}
        >
          <SearchIcon />
        </Button>
      </Box>

      <Grid item sx={{mb: 2}}>
     <FormControl sx={{ minWidth: 140 }}>
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            displayEmpty
            label=""
            sx={{
            //   minHeight: 30,
              minWidth: 120,
              p: 1,
            }}
          >
            <MenuItem value="">
              Filter By
            </MenuItem>
            <MenuItem value={1}>Option 1</MenuItem>
            <MenuItem value={2}>Option 2</MenuItem>
            <MenuItem value={3}>Option 3</MenuItem>
          </Select>
        </FormControl>
      </Grid>

          </Grid> */}
          <br/>
          
        <Grid container spacing={2}>
            <Grid item xs={8} md={12} lg={12}>
              <div style={{background: '#F8F8F8',  padding: '10px'}}>
               { <SettingsTopBox />}
                </div>
                
                 <br/>

                

                <div style={{background: '#F8F8F8',  padding: '10px'}}>
                <SettingsBottomBox />
               </div>


          <center style={{marginTop:"1rem"}}>
            <Button disabled={false} variant="contained" onClick={()=>{
              if(themeImageBlob){
                dispatch(uploadProfileSettings(preferredSettings,themeImageBlob,user && user.teacherId? user.teacherId:user && user.id ))
              }else{
              dispatch(updateUserSettings(preferredSettings,user && user.teacherId? user.teacherId:user && user.id ))
              }
            }}
              style={{ minWidth: '125px', backgroundColor: themeColor?themeColor:"#D72A34", marginLeft:  '1rem', paddingTop: '15px', paddingBottom: '15px', paddingLeft: '20px' }}>
              Soumettre
            </Button>
          </center>

            </Grid>
            
          </Grid>
      </Container>
    </>
  );
}
